footer {
    font-family: "Century Gothic";
    color: white;
    .inner-footer {
        border-top: 35px $blue solid;
        background: $blue;

        .contact-section {
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            border-bottom: 1px white solid;
            width: 80%;
            margin: 0 auto;
            height: 210px;
            div {
                width: 250px;
                h3 {
                    font-size: 1.35em;
                    font-weight: 400;
                    padding-bottom: 1rem;
                }
                ul {
                    list-style: none;
                    li {
                        display: flex;
                        padding: 0.4rem;
                        font-size: 1em;
                        transition: all ease-in-out 0.5s;
                        align-items: center;
                        .footer-icon {
                            font-size: 1.2em;
                            padding-right: 1rem;
                        }
                    }
                    li:hover {
                        color: $red;
                    }
                }
            }
            a {
                transition: all ease-in-out 0.5s;
                color: white;
                text-decoration: none;
            }
            a:hover {
                color: $red;
                cursor: pointer;
            }
        }
    }
    .bottom-footer {
        background: $blue;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        .copyright {
            margin: 0 auto;
            text-align: center;
            width: 90%;
            a {
                text-decoration: none;
                font-weight: bold;
                color: $grey-darker;
            }
            a:hover {
                color: $red;
            }
        }
    }
}
@media screen and (max-width: 840px) {
    footer {
        .contact-section {
            flex-direction: column;
            align-items: center !important;
            height: fit-content !important;
            width: 70%;
            div {
                border-top: 1px solid white;
                width: 80% !important;
                padding-bottom: 2rem;
                h3 {
                    text-align: center;
                    padding-top: 1rem;
                }

                ul {
                    list-style: none;
                    li {
                        text-align: left;
                    }
                }
            }
            .first-div {
                border-top: 0;
            }
        }
    }
    .bottom-footer {
        font-size: 0.9em !important;
        text-align: center;
        white-space: wrap;
        height: 65px !important;
    }
}

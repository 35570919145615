.nav {
    position: sticky !important;
    top: 0;
    width: 100%;
    font-family: "Century Gothic";
    z-index: 2;
    .top-nav {
        background: $blue;
        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 80%;
            height: 6vh;
            color: white;
            margin: 0 auto;
            .icon {
                padding-right: 0.5rem;
            }
            a {
                border: 3.5px white solid;
                padding: 0.4rem 0.9rem;
                text-decoration: none;
                color: white;
                transition: all ease-in-out 0.2s;
                border-radius: 2px;
            }
            a:hover {
                border: 3px $red solid;
                color: $red;
            }
        }
    }
    .bottom-nav {
        height: 13vh;
        width: 100%;
        color: $grey-darker;
        background-color: rgba(255, 255, 255, 0.937);
        .container {
            position: relative;
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            width: 80%;
            height: 100%;
            .logo {
                display: flex;
                align-items: center;
                img {
                    height: 85%;
                    width: auto;
                }
            }

            .menu {
                display: flex;
                align-items: center;
                ul {
                    list-style: none;
                    li {
                        display: inline-block;
                        padding: 1.5rem 0 1.5rem 1.5rem;
                        a {
                            text-decoration: none;
                            font-weight: 600;
                            color: $grey-darker;
                            transition: all ease-in-out 0.5s;
                        }
                        a:hover {
                            color: $blue;
                        }
                        .current-page {
                            color: $blue;
                        }
                    }
                }
            }
        }
    }
}
.mobile-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    width: 100%;
    font-family: "Century Gothic";
    height: 8vh;
    background-color: rgba(255, 255, 255, 0.937);
    z-index: 2;
    .logo {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 1.5rem;
        img {
            height: 85%;
            width: auto;
        }
    }

    .menu {
        button {
            background-color: transparent;
            text-decoration: none;
            font-weight: 600;
            color: $grey-darker;
            border: none;
            transition: all ease-in-out 0.5s;
            font-size: 1.7em;
            padding: 0 1.5rem;
        }
        button:hover {
            color: $blue;
        }
        ul {
            position: absolute;
            right: 0;
            top: 8vh;
            background-color: rgba(255, 255, 255, 0.937);
            width: 100%;
            height: 0;
            transition: all 0.3s ease-in;
            overflow: hidden;
            z-index: 1;
            li {
                display: flex;
                justify-content: center;
                width: 100%;

                a {
                    padding: 20px;
                    text-decoration: none;
                    color: $grey-darker;
                    border-bottom: 1px solid $blue;
                    transition: 0.5s;
                    font-weight: 600;
                }
                a:hover {
                    color: $blue;
                    cursor: pointer;
                    border-bottom: 1px solid $blue;
                }
                .current-page {
                    color: $blue;
                    border-bottom: 1px solid $blue;
                }
                .last-item {
                    border-bottom: 0;
                }
                .last-item:hover {
                    border-bottom: 0;
                }
            }
        }
    }
    .menu.active {
        ul {
            height: 350px;
        }
    }
}

@media screen and (max-width: 1250px) {
    .nav {
        .top-nav {
            .container {
                width: 90%;
            }
        }
        .bottom-nav {
            .container {
                width: 90%;
                .logo {
                    img {
                        height: 75%;
                    }
                }
                .menu {
                    ul {
                        li {
                            padding: 1rem 0 1rem 1rem;
                            a {
                                font-size: 0.9em;
                            }
                        }
                    }
                }
            }
        }
    }
}

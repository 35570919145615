.medecine {
    .section1 {
        background-color: $blue;
        height: 69vh;
        margin-bottom: 9vh;
        .container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row-reverse;
            width: 80%;
            height: 100%;
            margin: 0 auto;
            .content {
                display: flex;
                justify-content: center;
                flex-direction: column;
                height: 100%;
                font-family: "Century Gothic";
                color: white;
                width: 55%;
                @include grey-button-link;
                a {
                    width: 80%;
                }
            }
            .content > * {
                margin: 2rem;
            }
            .picture {
                border-radius: 50%;
                display: flex;
                align-items: flex-end;
                height: 100%;
                width: 45%;
                @include redline;
                margin-bottom: -3rem;
                .img {
                    background-repeat: no-repeat;
                    background-position: bottom;
                    background-image: url("../images/photo-ia3.png");
                    background-size: auto;
                    box-shadow: 10px 10px $blue-light;
                    border-radius: 50%;
                    width: 470px;
                    height: 470px;
                }
            }
        }
    }

    .section2,
    .section4 {
        width: 80%;
        margin: 0 auto;
        h2 {
            text-align: center;
            padding: 1.5rem;
            color: $blue;
        }
        .cards-container {
            display: flex;
            justify-content: center;
            justify-content: space-between;
            width: 100%;
            margin: 0 auto;
            .line {
                width: 60px;
                border-bottom: 3.5px solid $blue;
                margin-bottom: 1rem;
                margin-left: 2rem;
            }
            .card > * {
                padding: 0.3rem 0.8rem;
            }
            .card {
                display: flex;
                flex-direction: column;
                width: 48%;
                min-height: 10vh;
                border: 2px solid $grey;
                border-left: 7px solid $blue;
                color: $blue;
                box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.21);
                text-align: center;
                h3 {
                    font-weight: 600;
                    text-align: start;
                    padding: 1rem 2rem;
                }
                .left-title {
                    color: $green;
                }
                .right-title {
                    color: $grey-darker;
                }

                ul {
                    margin-left: 1.5rem;
                    list-style: none; /* Remove default list markers */
                    li {
                        font-family: "Century Gothic";
                        font-weight: 600;
                        font-size: 1em;
                        padding: 0.5rem;
                        position: relative; /* Allows us to position the marker manually */
                        padding-left: 2rem; /* Space for the custom marker */
                    }
                    li::before {
                        content: "";
                        display: inline-block; /* Allows sizing */
                        position: absolute;
                        left: 0; /* Position to the left of the text */
                        top: 50%; /* Center vertically */
                        transform: translateY(-50%); /* Fine-tune vertical alignment */
                    }
                }

                .left-card {
                    li::before {
                        background-image: url("../images/055-plus.png"); /* Use your image path */
                        background-size: contain; /* Adjust image size to fit the box */
                        width: 1rem; /* Set desired width */
                        height: 1rem; /* Set desired height */
                        background-repeat: no-repeat;
                    }
                }
                .right-card {
                    li::before {
                        background-image: url("../images/054-check.png"); /* Use your image path */
                        background-size: contain; /* Adjust image size to fit the box */
                        background-repeat: no-repeat;
                        width: 1.2rem; /* Set desired width */
                        height: 1.2rem; /* Set desired height */
                    }
                }
            }
        }
    }
    .section2 {
        margin-bottom: 4rem;
        .cards-container {
            .card {
                img {
                    margin-top: 0.8rem;
                    margin-left: 1.5rem;
                    height: auto;
                    width: 80px;
                }
            }
        }
    }
    .section3 {
        padding: 0.1rem 0;
        background-color: $grey-ten-percent;
        .container {
            height: fit-content;
            margin: 12vh auto;
            width: 80%;
            img {
                //border: 5px solid $grey;
                height: auto;
                width: 100%;
                box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.21);
            }
        }
    }
    .section4 {
        margin-top: 3rem;
        .cards-container {
            flex-wrap: wrap;
            .card {
                flex-direction: row;
                margin: 1rem 0;
                .num-cont {
                    padding: 1.5rem 0.5rem;
                    font-size: 40px;
                    color: $blue-light;
                    font-weight: 2000;
                }
                .text-cont {
                    padding: 1.5rem 0.5rem;
                    font-family: "Century Gothic";
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .medecine {
        .section1 {
            .container {
                width: 90%;
                .content {
                    h2 {
                        font-size: 1.5em;
                    }
                    p {
                        font-size: 0.9rem;
                    }
                }
                .content > * {
                    margin: 2rem 1rem !important ;
                }
            }
        }
    }
}
@media screen and (max-width: 1250px) {
    .medecine {
        .section1 {
            height: 60vh;
            margin-bottom: 18vh;
            .container {
                .picture {
                    .img {
                        height: 420px;
                        width: 420px;
                        background-size: 170%;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1000px) {
    .medecine {
        .section1 {
            @include mobile-margin;
            @include mobile-home-height;
            .container {
                flex-direction: column-reverse;
                justify-content: center !important;
                align-items: center;
                width: 100%;

                .content {
                    width: 90%;
                    text-align: center;
                    align-items: center;
                    h2,
                    p,
                    a {
                        margin: 1rem !important;
                        text-align: center;
                    }
                    height: 60%;
                }

                .redline {
                    height: 6px;
                    top: 6vh;
                }

                .picture {
                    margin: 0 auto;
                    justify-content: center;
                    align-items: center;
                    height: 540%;
                    margin-bottom: 0 !important;
                    width: 100%;
                    .img {
                        height: 320px;
                        width: 320px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 700px) {
    .medecine {
        .section1 {
            .container {
                .content {
                    h2,
                    a,
                    p {
                        margin: 0 !important;
                    }
                    p,
                    a {
                        font-size: 0.8em !important;
                        margin: 0.8rem !important;
                    }
                    a {
                        font-size: 0.7em !important;
                        padding: 0.5rem;
                    }
                    h2 {
                        font-size: 1.3em;
                    }
                }
            }
        }
        .section2,
        .section4 {
            .cards-container {
                flex-direction: column;
                width: 100%;
                height: fit-content;
                justify-content: center;
                .card {
                    width: 100%;
                }
            }
        }
        .section2 {
            .cards-container {
                .card {
                    margin: 1rem 0 !important;
                }
            }
        }
        .section3 {
            width: 100%;
            .container {
                width: 95%;
            }
        }
    }
}
@media screen and (max-width: 600px) {
    .medecine {
        .section1 {
            .container {
                .picture {
                    .img {
                        //height: 220px;
                        //width: 220px;
                    }
                }
            }
        }
    }
}

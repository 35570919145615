.fact-section {
    height: 30vh;
    background: $blue;
    width: 100%;
    margin: 0 100px 280px 0;
    
    .container {
        width: 85%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .fact {
            font-family: "Century Gothic";
            font-style: normal;
            font-weight: 600;
            font-size: 1.5em;
            height: 50%;
            width: 15%;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 1.5rem;

            .fact-number {
                font-size: 2.7em;
                color: $red;
                .plussign {
                    font-size: 1em;
                    color: $grey;
                    padding-left: 0.5rem;
                }
            }
            h1 {
                text-align: center;
            }
        }
    }
}
@media screen and (max-width: 1200px) {
    .fact-section {
        .container {
            .fact {
                width: 20%;
            }
            .fact-number {
                font-size: 2em;
            }
            h1 {
                font-size: 1em;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .fact-section {
        height: fit-content;
        .container {
            width: 100%;
            flex-direction: column;
            .fact {
                width: 45%;
                height: 15vh;
                margin: 1rem 0;
            }
        }
    }
}

@font-face {
  font-family: "Century Gothic";
  src:
    local("CenturyGothic"),
    url("../fonts/century-gothic/CenturyGothic.ttf") format("truetype");
}

//colors
$green:#77BF60;
$grey: rgb(191, 191, 191);
$grey-darker: rgb(127, 127, 127);
$grey-ten-percent: rgb(230, 230, 230);
$red: #f71111;
$blue: #173076;
$blue-opac: #1730768c;
$blue-light: rgb(88, 107, 137);
//arrow
$size: 17px;
$speed: 4s;
$peakopacity: 0.7;

//margins
@mixin mobile-margin {
  margin-bottom: 27px;
}
@mixin mobile-home-height {
  height: 85vh;
}
@mixin redline {
  .redline {
    position: absolute;
    top: 19vh;
    right: 0;
    background-color: $red;
    width: 50%;
    height: 10px;
    z-index: 1;
  }
}
@mixin grey-button-link {
  a {
    transition: all ease-in-out 0.2s;
    background-color: $grey-darker;
    padding: 0.9rem;
    color: white;
    text-decoration: none;
    width: 170px;
    text-align: center;
    font-weight: 600;
    border-radius: 2px;
  }
  a:hover {
    outline: 3px solid white;
  }
}

body {
  .arrows-container {
    display: flex;
    justify-content: center;
    height: 40px;
  }
  .arrows {
    position: relative;
    /**/
    width: $size;
    height: $size;
    transform: translate(-50%, -50%);
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-left: calc($size/3) solid rgba(0, 0, 0, $peakopacity);
      border-bottom: calc($size/3) solid rgba(0, 0, 0, $peakopacity);
      transform: translate(calc($size/3), calc($size * 4 / 3)) rotate(-45deg);
      animation: arrows $speed linear infinite;
    }
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-left: calc($size/3) solid rgba(0, 0, 0, $peakopacity);
      border-bottom: calc($size/3) solid rgba(0, 0, 0, $peakopacity);
      transform: translate(calc($size * 2 / 3), 0px) rotate(-45deg);
      animation: arrows $speed linear infinite calc($speed/-2);
    }
  }
}

@keyframes arrows {
  0% {
    border-left: calc($size/3) solid rgba(0, 0, 0, 0);
    border-bottom: calc($size/3) solid rgba(0, 0, 0, 0);
    transform: translate(calc($size/-6), calc($size * 4 / -6)) rotate(-45deg);
  }
  //10%,
  90% {
    border-left: calc($size/3) solid rgba(0, 0, 0, 0);
    border-bottom: calc($size/3) solid rgba(0, 0, 0, 0);
  }
  50% {
    border-left: calc($size/3) solid rgba(0, 0, 0, $peakopacity);
    border-bottom: calc($size/3) solid rgba(0, 0, 0, $peakopacity);
    transform: translate(calc($size/-6), 0px) rotate(-45deg);
  }
  100% {
    border-left: calc($size/3) solid rgba(0, 0, 0, 0);
    border-bottom: calc($size/3) solid rgba(0, 0, 0, 0);
    transform: translate(calc($size/-6), $size * calc(4 / 6)) rotate(-45deg);
  }
}
//dropdown
.dropdown-menu {
  display: none;
  background-color: rgba(255, 255, 255, 0.937);
  list-style: none;
  position: fixed;
  top: 19vh;
  width: 200px;
  height: fit-content;
  z-index: 10;
  li {
    padding: 0.7rem 0 0.7rem 0.7rem;
    a {
      text-decoration: none;
      font-weight: 600;
      color: $grey-darker;
      transition: all ease-in-out 0.5s;
    }
    a:hover {
      color: $blue;
    }
  }
}
#dropdown-menu-apropos.active {
  display: list-item;
  right: 6%;
}
#dropdown-menu-services.active {
  display: list-item;
  right: 29%;
}
#model {
  cursor: zoom-in;

  
}

#model.full {
  display: block;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh !important;
  width: 100%;
  cursor: pointer;
}
#close-model {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 999;
  top: 20px;
  right: 20px;
  color: $grey-darker;
  font-size: 3.5rem;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .redline {
    height: 6px !important;
    top: 8vh !important;
  }
  .accueil {
    .redline {
      top: 0vh !important;
    }
  }
}

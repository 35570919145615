.placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $grey-darker;
    font-family: "Century Gothic";
    height: 94vh;
    text-align: center;
    h1{
        padding: 1rem;
        text-align: center;
        font-size: 2.5em;
    }
    img {
        padding: 3rem 0;
        height: 200px;
        width: auto;
    }
}
@media screen and (max-width: 840px) {
    .placeholder {
        h1{
        font-size: 1.8em;
        }
        font-size: .8em;
        img {
            height: 100px;
        }
    }
}

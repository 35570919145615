.notfound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: $grey-darker;
    font-family: "Century Gothic";
    height: 75vh;
    .container {
        width: 80%;
        margin: 0 auto;
        h1 {
            color: $blue;
            font-size: 5.5em;
            font-weight: 900;
        }
        h2 {
            color: $blue;
            font-size: 2em;
            padding: 1.5rem 0;
        }
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $blue;
            color: white;
            text-decoration: none;
            text-align: center;
            width: 250px;
            height: 50px;
            font-size: 2em;
            cursor: pointer;
            transition: all ease-in-out;
        }
        a:hover {
            outline: 2px solid $grey;
            background-color: $blue;
            color: $grey;
        }
        img {
            padding: 2rem 0;
            height: 200px;
            width: auto;
        }
    }
}
@media screen and (max-width: 840px) {
    .notfound {
        .container {
          h2 {
            padding: .8rem 0;
        }
            font-size: 0.8em;
            a {
                width: 150px;
                height: 40px;
                font-size: 1.5em;
            }
            img {
                 padding: 1rem 0;
                width: 90%;
                height: auto;
            }
        }
    }
}

.services {
    .section1 {
        height: 68vh;
        margin-bottom: 9vh;
        width: 100%;
        z-index: -1;
        background-repeat: no-repeat;
        background-position: bottom;
        background-image: linear-gradient(rgba(23, 48, 118, 0.6), rgba(23, 48, 118, 0.6)), url("../images/galien-2.jpg");
        background-size: cover;
        @include redline;
        margin-bottom: 9vh;

        .container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 50%;
            height: 100%;
            margin: 0 auto;
            color: white;
            font-family: "Century Gothic";
            @include grey-button-link;
            text-align: center;
            h1,
            p {
                padding: 1.2rem;
                text-align: center;
                font-size: 1.1em;
            }
            h1 {
                font-size: 1.5em;
                padding: 2rem;
            }
            a {
                margin: 1.2rem;
            }
        }
    }
    .section2,
    .section3 {
        height: fit-content;
        margin: 9vh 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
            color: $blue;
            text-align: center;
            padding-bottom: 1rem;
        }
        .cards-container {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 90%;
            min-height: 75vh;
            margin: 0 auto;
            .card > * {
                padding: 1rem 1.5rem;
            }
            .card {
                display: flex;
                align-items: center;
                flex-direction: column;
                width: 26%;
                min-height: 40vh;
                border: 2px solid $grey;
                border-top: 6px solid $blue;
                margin: 1rem;
                color: $blue;
                box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.21);
                background-color: white;
                h3 {
                    text-align: center;
                    padding: 1rem;
                }
                .star {
                    padding-right: 0.3rem;
                    color: $grey;
                }

                img {
                    margin-top: 1rem;
                    height: 85px;
                    width: auto;
                }
                .med-trav-link {
                    color: $blue;
                    font-weight: 700;
                    text-decoration: none;
                }
                .med-trav-link:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .section3 {
        background-color: $grey-ten-percent;
        padding: 3rem 0;
    }
    .section4 {
        margin-bottom: 9vh;
        height: fit-content;
        h2 {
            color: $blue;
            text-align: center;
            padding-bottom: 1.5rem;
        }
        .container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 0px 0px;
            grid-template-areas:
                "grid1 grid1 grid2"
                "grid3 grid4 grid4";
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            gap: 30px;
            width: 80%;
            margin: 0 auto;
            .grid1 {
                background: url(../images/galien-2.jpg) no-repeat center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
                background-position: 50% 95%;
                grid-area: grid1;
            }
            .grid2 {
                background: url(../images/galien-137.jpg) no-repeat center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
                background-position: center;
                grid-area: grid2;
            }
            .grid3 {
                background: url(../images/galien-13.jpg) no-repeat center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
                background-position: center;
                grid-area: grid3;
            }
            .grid4 {
                background: url(../images/galien-27.jpg) no-repeat center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
                background-position: 50% 75%;
                grid-area: grid4;
            }

            .grid {
                height: 35vh;
                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
    .mobile-section4 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70vh;
        width: 100%;
        .container {
            margin: 0 auto;
            width: 90%;
            height: 70%;

            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
        .swiper-wrapper {
            margin-bottom: 2rem !important;
        }
    }
    .line {
        width: 85px;
        border-bottom: 3.5px solid $blue;
        margin-bottom: 1rem;
    }
}
@media screen and (max-width: 1000px) {
    .services {
        .section2,
        .section3 {
            .cards-container {
                width: 100%;
            }
        }
    }
}
@media screen and (max-width: 800px) {
    .services {
        .section1 {
            @include mobile-home-height;
            @include mobile-margin;
            .container {
                width: 90%;
                h1 {
                    font-size: 2em;
                }
                p {
                    padding: 0.8rem;
                }
            }
        }
        .section2,
        .section3 {
            .cards-container {
                width: 100%;
                height: fit-content;
                .card {
                    width: 80%;
                }
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .services {
        .section4 {
            .container {
                display: flex !important;
                flex-flow: column wrap;
            }
        }
    }
}

@import "components";
@import "placeHolder";
@import "navbar";
@import "footer";
@import "accueil";
@import "apropos";
@import "medecinetravail";
@import "notfound";
@import "services";
@import "factscta";

//Global Settings
* {
    padding: 0;
    margin: 0;
    text-align: left;
}
html {
    scroll-behavior: smooth;
    scroll-padding-top: 19vh;
}

.apropos {
    height: fit-content;
    width: 100%;
    .section1 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: $blue;
        min-height: 140px;
        .container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            width: 60%;
            height: 100%;
            p {
                padding-top: 1.6rem;
                font-size: 1.1em;
                padding: 1.5rem;
                font-style: italic;
                text-align: center;
                font-family: "Century Gothic";
                color: white;
            }
        }
    }
    .section2 {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        .container1,
        .container2,
        .container3,
        .container4 {
            width: 100%;
            .wrapper {
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
                margin: 0 auto;
                width: 80%;
                color: $blue;
                height: 50vh;
                .img-cont {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 50%;
                    img {
                        height: 80%;
                        width: 100%;
                        border: 2px solid $grey;
                        object-fit: cover;
                    }
                }
                .text-cont {
                    padding-left: 1.5rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 100%;
                    width: 50%;
                    h2 {
                        padding-bottom: 1rem;
                        font-size: 2em;
                    }
                    p {
                        padding-top: 1.6rem;
                        font-size: 1.2em;
                        width: 90%;
                        font-family: "Century Gothic";
                    }
                }
            }
        }
        .container2 {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: $grey-ten-percent;
            padding: 3rem 0;

            h2 {
                color: $blue;
                text-align: center;
                padding-bottom: 1rem;
                font-size: 2em;
            }

            .wrapper {
                flex-direction: row-reverse;
                .text-cont {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 100%;
                    width: 50%;
                    padding-left: 0;

                    ul {
                        padding-inline-start: 0;
                        list-style-position: inside;
                        list-style: none;
                        width: 70%;
                        li {
                            display: flex;
                            text-align: center;
                            align-items: center;
                            justify-content: center;
                            background-color: white;
                            width: 100%;
                            padding: 1rem;
                            margin-bottom: 0.5rem;
                            border: 2px solid $grey;
                            box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.21);
                            font-family: "Century Gothic";
                            font-size: 1.1em;
                            font-weight: 950;
                        }
                    }
                }
                .img-cont {
                    img {
                        object-fit: none;
                        object-position: 50% 90%;
                    }
                }
            }
            .bottom-text {
                margin: 0 auto;
                width: 80%;
                color: $blue;
                p {
                    width: 100%;
                    font-size: 1.2em;
                    font-family: "Century Gothic";
                }
            }
        }

        .container3 {
            .wrapper {
                flex-direction: row-reverse;
            }
            .text-cont {
                padding-left: 0;
            }
            .img-cont {
                img {
                    object-position: 50% 75%;
                }
            }
        }
        .container4 {
            .img-cont {
                img {
                    object-fit: none;
                    object-position: 25% 68%;
                }
            }
        }
        .line {
            width: 85px;
            border-bottom: 3.5px solid $blue;
        }
        .grey-line {
            width: 40%;
            border-bottom: 1px solid $grey;
        }
    }
}

@media screen and (max-width: 1000px) {
    .apropos {
        .section2 {
            .container1,
            .container2,
            .container3,
            .container4 {
                .wrapper {
                    height: 70vh;
                }
            }
        }
    }
}

@media screen and (max-width: 770px) {
    .apropos {
        .section1 {
            .container {
                width: 95%;
            }
        }
        .section2 {
            width: 100%;
            .container1,
            .container2,
            .container3,
            .container4 {
                .wrapper {
                    flex-direction: column;
                    height: fit-content;
                    width: 100%;
                    padding: 2rem 0;
                    align-items: center;
                    .text-cont {
                        display: flex;
                        justify-content: center !important;
                        align-items: center !important;
                        margin: 0 !important;
                        width: 90%;
                        padding-left: 0 !important;
                        ul {
                            margin: 0 !important;
                            display: inline-block;
                            width: 100%;
                            text-align: center;
                            li {
                                text-align: center;
                                width: auto;
                            }
                        }
                        p {
                            text-align: center;
                        }
                    }
                    .img-cont {
                        padding: 1.5rem 0;
                        width: 90%;
                    }
                }
                .bottom-text {
                    p {
                        text-align: center;
                    }
                }
                h2 {
                    text-align: center !important;
                    font-size: 1.6em !important;
                }
            }
        }
    }
}

.accueil {
    .section1 {
        background-color: $blue;
        height: 69vh;
        margin-bottom: 9vh;
        .container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            .content {
                display: flex;
                justify-content: center;
                flex-direction: column;
                height: 100%;
                font-family: "Century Gothic";
                color: white;
                width: 35%;
                padding-left: 10%;
                @include grey-button-link;
            }
            .content > * {
                margin: 2rem;
            }
            .picture {
                height: 100%;
                width: 65%;
                @include redline;
                .redline {
                    top: 0vh;
                    width: 90%;
                }
                img {
                    width: 100%;
                    height: 100%;
                    vertical-align: middle;
                    object-fit: cover;
                }
            }
        }
    }
    .section2 {
        height: 90vh;
        .cards-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80%;
            height: 100%;
            margin: 0 auto;

            .card {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-evenly;
                width: 25%;
                height: 45%;
                border: 2px solid $grey;
                border-top: 6px solid $blue;
                margin: 1rem;
                color: $blue;
                box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.21);
                p {
                    min-height: 65px;
                }
                img {
                    height: 20%;
                    width: auto;
                }
            }
            .card > * {
                padding: 0 1.5rem;
                text-align: center;
            }
        }
        #medicaux-card:hover,
        #specialises-card:hover {
            cursor: pointer;
        }
    }
    .section3 {
        height: 50vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .section-cards-container {
            width: 100%;
            background-color: $grey-ten-percent;
        }
        .cards-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80%;
            height: fit-content;
            margin: 0 auto;
            .card {
                display: flex;
                align-items: center;
                //justify-content: center;
                flex-direction: column;
                width: 30%;
                height: 50vh;
            }
            .left-card {
                a {
                    margin-bottom: 1.5rem;
                    border: 2px $blue solid;
                    padding: 0.7rem 2rem;
                    text-decoration: none;
                    color: $blue;
                    transition: all ease-in-out 0.2s;
                    border-radius: 2px;
                    vertical-align: middle;
                    font-weight: 600;
                }
                a:hover {
                    border: 3px $red solid;
                    color: $red;
                }
            }
            .left-card,
            .right-card {
                background: white;
                h3 {
                    border-bottom: 1px $blue solid;
                }
                p,
                h3 {
                    padding: 1.5rem;
                    text-align: center;
                    color: $blue;
                }
                .img-container {
                    width: 100%;
                    height: 28%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        //object-fit: fill;
                        //object-fit: contain;
                    }
                    #consultpic {
                        object-position: 20% 75%;
                    }
                }
            }
            .middle-card {
                justify-content: center;
                background-color: $blue;
                color: white;
                .content {
                    padding: 1rem;
                    p,
                    h3 {
                        text-align: center;
                    }
                }
                .first {
                    border-bottom: 1px solid white;
                }
                img {
                    height: 80px;
                    width: auto;
                }
            }
        }
    }
}
@media screen and (max-width: 1250px) {
    .accueil {
        .section1 {
            .container {
                .content {
                    padding-left: 5%;
                    h2 {
                        font-size: 1.4em;
                    }
                    p {
                        font-size: 0.9em;
                    }
                }
                .content > * {
                    margin: 1rem;
                }
            }
        }
        .section2 {
            .cards-container {
                width: 90%;
                .card {
                    width: 30%;
                    height: 50%;
                    margin: 0.5rem;
                    h3 {
                        font-size: 1em;
                    }
                }
                .card > * {
                    padding: 1rem;
                }
            }
        }
        .section3 {
            .cards-container {
                width: 90%;
            }
        }
    }
}
@media screen and (max-width: 1000px) {
    .accueil {
        .section1 {
            @include mobile-margin;
            @include mobile-home-height;
            .container {
                flex-direction: column-reverse;
                .content {
                    width: 90%;
                    text-align: center;
                    align-items: center;
                    h2,
                    p,
                    a {
                        text-align: center;
                    }
                    padding: 40px 0;
                    height: 35%;
                }
                .picture {
                    height: 60%;
                    .redline {
                        height: 6px;
                        top: 6vh;
                    }
                    img {
                        object-position: middle;
                    }
                    width: 100%;
                }
            }
        }
        .section2 {
            height: fit-content;
            @include mobile-margin;
            .cards-container {
                width: 100%;
                flex-direction: column;
                .card {
                    width: 80%;
                    height: 40vh;
                    margin: 1rem;
                    h3 {
                        font-size: 1em;
                    }
                }
                .card > * {
                    padding: 1rem;
                }
            }
        }
        .section3 {
            height: fit-content;
            @include mobile-margin;
            .cards-container {
                width: 100%;
                flex-direction: column;
                .card {
                    width: 80%;
                    min-height: 50vh;
                    img{
                      height: 115px !important;  
                    }
                    
                }
                .left-card {
                    height: auto;
                    min-height: 50vh !important;
                }
                .middle-card {
                    img {
                        padding: 1rem;
                    }
                }
            }
        }
    }
}
